import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import { getDashboardDataApiCall, getGroupBrandListApiCall, exportTransactionApiCall } from '../../services/dashboard/getDashboardDataApiCall';
import { dashboardSelectors } from '../../redux/dashboard';
import Select from 'react-select';
import { getBrandDataByFilterDashboardAction, getBrandList, getXeroInvoiceAction } from '../../redux/actions';
import DateFnsUtils from '@date-io/date-fns';
import ChartComponent from './chartComponent';
import { CLEAR_DASHBOARD_DATA, GET_DASHBOARD_DATA } from '../../redux/dashboard/actionTypes';
import '../../assets/styles/dashboard.scss'
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Roles } from '../../utils/constants/constant';
import { notificationOpts } from '../../config';
import {
    error as errorNotificationAction,
    success as successNotificationAction
} from 'react-notification-system-redux';
import classNames from 'classnames';
import { newBrandBranchSelectors } from '../../redux/newBrandBranch';
import { getDistributors, getUploadedDate } from '../../services/newBrandBranch/checkBrandExistApiCall';
// import { getDistributors } from '../../services/newBrandBranch/checkBrandExistApiCall';
import SessionExpiryModal from '../../components/sessionExpiryModal';
import ViewLastTransactionModal from '../../components/ViewLastTransactionModal';
import { GetSessionValue } from '../../utils/sessionStorage';

export default function Dashboard() {

    const dispatch = useDispatch();
    const Brandstate = useSelector(
        dashboardSelectors.getDashboardstate
    );
    const userstate = useSelector(
        dashboardSelectors.getUserstate
    );
    const groupBrandListstate = useSelector(
        dashboardSelectors.getGroupBrandListstate
    );
    const getDistributorsState = useSelector(
        newBrandBranchSelectors.getDistributorsState
    );
    const getUploadedDatestate = useSelector(
        newBrandBranchSelectors.getUploadedDatestate
    );

    let defaultOption = { label: 'All', value: 'All' }
    const [filter, setFilter] = useState({});
    const [brandList, setbrandList] = useState([]);
    const [tempBrandList, setTempBrandList] = useState([]);
    const [groupBrandList, setGroupBrandList] = useState([]);
    const [groupBrandDetails, setGroupBrandDetails] = useState([]);
    const [isInvoiceData, setIsInvoiceData] = useState(false);

    const [publisherList, setPublisherList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(defaultOption);
    const [selectedMonth, setselectedMonth] = useState(null);
    const [selectedPublisher, setSelectedPublisher] = useState(defaultOption);
    const [selectedGroupBrand, setSelectedGroupBrand] = useState(defaultOption);
    const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
    const [localLoader, setLocalLoader] = useState(false);
    const [ingestionLogDate, setIngestionLogDate] = useState({});
    const [ingestionLogList, setIngestionLogList] = useState([]);
    const [showIngestionLogList, setShowIngestionLogList] = useState(false);
    const [selectedTranCustomerType, setSelectedTranCustomerType] = useState(defaultOption);
    const [havingMultipleCustomers, setHavingMultipleCustomers] = useState(false);
    const [offerTypeList, setOfferTypeList] = useState([]);
    const [selectedOfferType, setSelectedOfferType] = useState({ label: 'All', value: 'All' });
    const [tranCustomerTypes, setTranCustomerTypes] = useState([
        { label: 'All', value: 'All' },
        { label: "New Customers", value: "NEW USER" },
        { label: "Existing Customers", value: "REPEAT USER" }
    ]);

    const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
    let cardList = [
        { defaultValue: 0, type: "text", iconType: "material", icon: "check_circle_outline", title: "Conversions", field: "conversion" },
        { defaultValue: 0, type: "number", iconType: "fa", icon: "fa-gbp", title: "GMV (£)", field: "revenueCount" },
        { defaultValue: 0, type: "number", iconType: "fa", icon: "fa-gbp", title: "Total Cashback (£)", field: "totalCost" },
        { defaultValue: 0, type: "number", iconType: "fa", icon: "fa-gbp", title: "Total Success Fee (£)", field: "totalSuccessFee" }
    ];

    useEffect(async () => {

        let sessionExpiryModal = false;
        let sessionExpired = await GetSessionValue('sessionExpired');
        if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
            sessionExpiryModal = true
        }
        setSessionExpiryModal(sessionExpiryModal);
        if (sessionExpiryModal) {
            return;
        }

        // getDashboardData()
        getBrandNameApiCall("");
        dispatch(getGroupBrandListApiCall());
        dispatch(getDistributors());
        dispatch(getUploadedDate(""));
        return () => {
            dispatch({
                type: CLEAR_DASHBOARD_DATA
            })
        }
    }, []);

    useEffect(() => {
        if (selectedBrand.label || selectedMonth) {
            setSelectedInvoiceNumber("");
            getDashboardData("",isInvoiceData)
        }
    }, [selectedBrand, selectedMonth]); //isInvoiceData

    useEffect(() => {
        if (selectedPublisher || selectedTranCustomerType || selectedOfferType) {
            getDashboardData(selectedInvoiceNumber, isInvoiceData)
        }
    }, [selectedPublisher, selectedTranCustomerType, selectedOfferType]);

    useEffect(() => {
        if (selectedGroupBrand.label == "All") {
            setbrandList(tempBrandList);
            // getDashboardData();
        } else if (selectedGroupBrand.label) {
            let g_Detail = groupBrandDetails.filter(a => a.groupId == selectedGroupBrand.value);
            if (g_Detail && g_Detail[0] && g_Detail[0].group_brand_ids) {
                setbrandList(g_Detail[0].group_brand_ids);
            }
            // getDashboardData();
        }
        setSelectedBrand(defaultOption);
    }, [selectedGroupBrand]);

    useEffect(() => {
        if (groupBrandListstate.data.length) {
            let list = [{ label: 'All', value: 'All' }];
            groupBrandListstate.data.forEach(element => {
                list.push({ _id: element._id, label: element.group_name, value: element.groupId });
            });
            setGroupBrandList(list);
            setGroupBrandDetails(groupBrandListstate.data);
        } else if (groupBrandListstate.errorCode == "9001") {
            setSessionExpiryModal(true);
        }
    }, [groupBrandListstate.data]);

    useEffect(() => {
        if (getDistributorsState && getDistributorsState.data && getDistributorsState.data.distributors) {
            let list = [{ label: 'All', value: 'All' }];
            getDistributorsState.data.distributors.forEach(element => {
                if(!element.parentPublisherId) {
                    list.push({ label: element.distributors_display_name, value: element.distributors_name });
                }
            });
            setPublisherList(list);
        }
    }, [getDistributorsState.data]);

    useEffect(() => {
        if (getUploadedDatestate && getUploadedDatestate.data && getUploadedDatestate.data.ingestionLogDate) {
            setIngestionLogDate(getUploadedDatestate.data.ingestionLogDate);
            setIngestionLogList(getUploadedDatestate.data.ingestionLogList);
        }
    }, [getUploadedDatestate && getUploadedDatestate.data]);

    const getDashboardData = (selectedInvoiceNumber, isInvoiceData) => {
        // let year, month;
        // year = 2021;
        // month = 12;
        // let dateForInvoice = new Date(year, (month - 1), 1)
        // let startDate = moment(dateForInvoice).startOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        // let endDate = moment(dateForInvoice).endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        let filter = {
            // creadeDate: { $gte: startDate, $lte: endDate }
        }
        let customerType = "";
        if (selectedBrand.label != "All") {
            filter.brandName = selectedBrand.label
        }
        if (selectedMonth) {
            filter.monthNumber = new Date(selectedMonth).getMonth() + 1
            filter.year = new Date(selectedMonth).getFullYear()
        }
        if (selectedPublisher.value != "All") {
            filter.data_source = selectedPublisher.value
        }
        let distributor_id = "";
        if (userstate.role == Roles.bank) {
            // customerType = userstate.customerType;
            if (userstate.distributor_id) {
                distributor_id = "&distributor_id=" + userstate.distributor_id;
            }
        }
        // if (selectedGroupBrand.label != "All" && selectedBrand.label == "All") {
        //     filter.groupsOfBrands = brandList;
        // }
        let groupAndBrandId = "";
        if (selectedGroupBrand.label != "All") {
            groupAndBrandId += "&groupId=" + selectedGroupBrand.value;
        }
        if (selectedBrand.label != "All") {
            groupAndBrandId += "&brandId=" + selectedBrand.value;
        }

        if((selectedGroupBrand.value == "All" && selectedBrand.value == "All") || !selectedMonth) {
            setIsInvoiceData(false);
            setSelectedInvoiceNumber("");
            isInvoiceData = false;
            selectedInvoiceNumber = "";
        }

        let is_invoice_data = "&is_invoice_data=" + isInvoiceData;
        let data = `?customerType=${customerType}${distributor_id}${is_invoice_data}${groupAndBrandId}&filter=${encodeURIComponent(JSON.stringify(filter))}`
        if (havingMultipleCustomers && selectedTranCustomerType.value != "All" && (selectedGroupBrand.value != "All" || selectedBrand.value != "All") && selectedPublisher.value == "ATR") {
            data += "&tranCustomerType=" + selectedTranCustomerType.value;
        }
        if (isInvoiceData && selectedInvoiceNumber) {
            data += "&selectedInvoiceNumber=" + selectedInvoiceNumber;
        }
        if (offerTypeList && offerTypeList.length > 0 && (selectedGroupBrand.label != "All" || selectedBrand.value != "All")
                && selectedPublisher.value != "All" && selectedOfferType.value != "All") {
            data += "&selectedOfferType=" + selectedOfferType.value;
        }
        setFilter(filter);
        dispatch({ type: GET_DASHBOARD_DATA, payload: { data: [], loader: true } });
        let version="v1"
        dispatch(getDashboardDataApiCall(data,version, filter));
        let getIngObj = {};
        if (filter.data_source != "All") {
            getIngObj.data_source = filter.data_source;
        }
        if (selectedBrand.label == "All" && selectedGroupBrand.label != "All") {
            getIngObj.groupId = selectedGroupBrand.value;
        }
        if (selectedBrand.label != "All") {
            getIngObj.brandId = selectedBrand.value;
        }
        // if (selectedMonth) {
        //     getIngObj.monthNumber = filter.monthNumber;
        //     getIngObj.year = filter.year;
        // }
        dispatch(getUploadedDate(getIngObj));
    }

    useEffect(() => {
        if (Brandstate.data && Brandstate.data.is_two_customer) {
            setHavingMultipleCustomers(Brandstate.data.is_two_customer.is_two_customer)
        }
    }, [Brandstate && Brandstate.data && Brandstate.data.is_two_customer]);

    useEffect(() => {
        if (Brandstate.data && Brandstate.data.offerTypeList && Brandstate.data.offerTypeList.length > 0) {
            setOfferTypeList(Brandstate.data.offerTypeList);
        } else {
            setOfferTypeList([]);
        }
    }, [Brandstate && Brandstate.data && Brandstate.data.offerTypeList]);

    // useEffect(() => {
    //     if(isInvoiceData && Brandstate.data && Brandstate.data.invoiceList && Brandstate.data.invoiceList.length) {
    //         setSelectedInvoiceNumber(Brandstate.data.invoiceList[0].xero_invoiceNumber);
    //     }
    // }, [Brandstate && Brandstate.data && Brandstate.data.invoiceList && Brandstate.data.invoiceList.length]);

    const getBrandNameApiCall = (value) => {
        // let filterCriteria = {
        //     'criteria': [
        //         {
        //             'key': 'name',
        //             'value': value,
        //             'type': 'regexOr'
        //         }
        //     ],
        //     customerType: userstate.customerType
        // }
        // dispatch(getBrandDataByFilterDashboardAction(filterCriteria, res => {
        //     if (res && res.length) {
        //         // res.forEach((item, index) => {
        //         //     item.label = item.name
        //         //     item.value = item.brandId
        //         // })
        //         res.forEach((item, index) => {
        //             if (userstate.customerType && userstate.customerType.length > 0) {
        //                 item.label = item.name;
        //             } else {
        //                 item.label = item._id;
        //             }
        //             // item.value = 0;
        //             item.value = index;
        //         })
        //         let arr = [defaultOption, ...res]
        //         setbrandList(arr);
        //         setTempBrandList(arr);
        //     }
        // }))

        dispatch(getBrandList(res => {
            if (res && res.length) {
                let arr = [defaultOption, ...res]
                setbrandList(arr);
                setTempBrandList(arr);
            }
        }))
    }

    const addAdditionalValue = (value) => {
        if (value && value.indexOf("-") != -1 && value.split("-")[1].length == 1) {
            value = value.split("-")[0] + "0" + value.split("-")[1];
        }
        return value ? value.replaceAll("-", "") : "";
    }

    const downloadExcel = () => {

        // let field = ['Brand', 'Month', 'Total Transaction Amount', 'Total Cashback Amount', 'Total Commissions'], exportData = [];
        // if (Brandstate && Brandstate.data && Brandstate.data.exportExcel) {

        //     Brandstate.data.exportExcel.forEach(e1 => {

        //         e1.brandListPerBranch = e1.brandListPerBranch.sort((a, b) => {
        //             a._id.newTransMonth = a._id.transMonth;
        //             b._id.newTransMonth = b._id.transMonth;
        //             a._id.newTransMonth = addAdditionalValue(a._id.newTransMonth);
        //             b._id.newTransMonth = addAdditionalValue(b._id.newTransMonth);
        //             return parseInt(a._id.newTransMonth) - parseInt(b._id.newTransMonth);
        //         });

        //         e1.brandListPerBranch.forEach((e2, index) => {
        //             if (index == 0) {
        //                 exportData.push({
        //                     brandName: e1._id, transMonth: e2._id.transMonth, totalTransactionAmount: e2.totalTransactionAmount,
        //                     totalCashbackAmount: e2.totalCashbackAmount, totalCommissions: e2.totalCommissions
        //                 })
        //             } else {
        //                 exportData.push({
        //                     brandName: "", transMonth: e2._id.transMonth, totalTransactionAmount: e2.totalTransactionAmount,
        //                     totalCashbackAmount: e2.totalCashbackAmount, totalCommissions: e2.totalCommissions
        //                 })
        //             }
        //         });
        //     });
        //     ExcelDownload(exportData, 'RevenueDistribution', field);
        // }

        let filter = {}
        let customerType = "";
        if (selectedBrand.label != "All") {
            filter.brandName = selectedBrand.label
        }
        if (selectedMonth) {
            filter.monthNumber = new Date(selectedMonth).getMonth() + 1
            filter.year = new Date(selectedMonth).getFullYear()
        }
        if (selectedPublisher.value != "All") {
            filter.data_source = selectedPublisher.value
        }
        let distributor_id = "";
        if (userstate.role == Roles.bank) {
            if (userstate.distributor_id) {
                distributor_id = "&distributor_id=" + userstate.distributor_id;
            }
        }
        if (selectedGroupBrand.label != "All" && selectedBrand.label == "All") {
            filter.groupsOfBrands = brandList;
        }
        let groupAndBrandId = "";
        if (selectedGroupBrand.label != "All") {
            groupAndBrandId += "&groupId=" + selectedGroupBrand.value;
        }
        if (selectedBrand.label != "All") {
            groupAndBrandId += "&brandId=" + selectedBrand.value;
        }
        if (offerTypeList && offerTypeList.length > 0 && (selectedGroupBrand.label != "All" || selectedBrand.value != "All")
                && selectedPublisher.value != "All" && selectedOfferType.value != "All") {
                groupAndBrandId += "&selectedOfferType=" + selectedOfferType.value;
        }
        let is_invoice_data = "&is_invoice_data=" + isInvoiceData;
        let data = `?customerType=${customerType}${distributor_id}${is_invoice_data}${groupAndBrandId}&from=admin&filter=${encodeURIComponent(JSON.stringify(filter))}`
        if (isInvoiceData && selectedInvoiceNumber) {
            data = data + "&selectedInvoiceNumber=" + selectedInvoiceNumber;
        }
        setLocalLoader(true);
        dispatch(exportTransactionApiCall(data, response => {
            setLocalLoader(false);
            if (response && response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = "File exported successfully. You will receive an email with a link to download the file.";
                dispatch(successNotificationAction(notification));
            } else if (response && response.errorCode) {
                let notification = { ...notificationOpts }
                notification.message = response.message;
                dispatch(errorNotificationAction(notification))
            }

            // let field = ['S no.', 'DATE', 'TRANSACTION ID', 'BRAND NAME', 'BRANCH NAME',
            // 'MERCHANT ID','LOCATION','CARD (LAST 4 DIGITS)','PUBLISHER','AMOUNT (£)','CASHBACK (£)','SUCCESS FEE'], exportData = [];
            // if (response.respCode && response.transactions) {
            //     response.transactions.forEach((e1, index) => {
            //         exportData.push({
            //             s_no : index + 1, createdDate: e1.createdDate ,  transactionId: e1.transactionId,  brandName: e1.brandName,  BranchName: e1.BranchName,  
            //             Merchant_ID: e1.Merchant_ID,  postcode: e1.postcode, cardLastNumber: e1.cardLastNumber,  data_source: e1.data_source,  
            //             amount: e1.amount,  totalCost: e1.totalCost, successFee: e1.successFee
            //         })
            //     });
            //     ExcelDownload(exportData, 'Transactions', field);
            // } 
        }));
    }

    const viewInvoice = () => {
        let data = {
            // month: new Date(selectedMonth).getMonth() + 1,
            // year: new Date(selectedMonth).getFullYear(),
            // brand_name: selectedBrand.label
            xero_invoiceNumber: selectedInvoiceNumber
        }

        if (!selectedInvoiceNumber && Brandstate?.data?.invoiceList?.length == 1) {
            data.xero_invoiceNumber = Brandstate.data.invoiceList[0].xero_invoiceNumber;
        }

        dispatch(getXeroInvoiceAction(data, res => {
            if (res && res.invoiceURL) {
                window.open(res.invoiceURL, '_blank');
            } else if (res && res.respMessage) {
                let notification = { ...notificationOpts }
                notification.message = res.respMessage;
                dispatch(errorNotificationAction(notification))
            } else {
                let notification = { ...notificationOpts }
                notification.message = "Invoice is not created.";
                dispatch(errorNotificationAction(notification))
            }
        }));
    }

    const numberWithCommas = (n) => {
        var parts = n.toString().split(".");

        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;

        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }

    const checkInvoiceCanView = () => {
        let flag = false;
        if(Brandstate && Brandstate.data && Brandstate.data.invoiceList) {
            Brandstate.data.invoiceList.forEach(element => {
                if(element.xero_invoiceNumber == selectedInvoiceNumber && element.canView) {
                    flag = true;
                }
            });
        }
        return flag;
    }

    return <>
        {(Brandstate.loader || localLoader) &&
            <div className="loading">Loading...</div>
        }
        <div className="heading m-3">
            <div className="row my-3">
                <div className="col-md-5 d-flex align-items-center">
                    <h2 className="m-0">
                        <span>
                            <Icon className="heading-icon">adjust</Icon>
                        </span>{' '}
                        Dashboard
                    </h2>
                </div>
                <div className="col-md-7 invoice-data-toogle align-items-right">
                    {userstate.role != Roles.bank && ((selectedGroupBrand && selectedGroupBrand.value != "All") ||
                        (selectedBrand && selectedBrand.value != "All")) &&
                        selectedMonth &&
                        <>
                            <div className='title'>All Data </div>
                            <div className="toggle-button-cover">
                                <div className="button-cover">
                                    <div className="button r" id="button-1">
                                        <input type="checkbox" className="checkbox" onChange={(e) => {
                                            setIsInvoiceData(!isInvoiceData);
                                            getDashboardData(selectedInvoiceNumber,!isInvoiceData);
                                        }} />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='title'>Invoiced Data</div>

                            {isInvoiceData && Brandstate.data && Brandstate.data.invoiceList && Brandstate.data.invoiceList.length > 0 &&
                                <>
                                    <select
                                        name="invoice_number"
                                        className="form-control dropdown inv-no-dropdown"
                                        value={selectedInvoiceNumber}
                                        onChange={(e) => {
                                            setSelectedInvoiceNumber(e.target.value);
                                            getDashboardData(e.target.value,isInvoiceData);
                                        }}>
                                        {Brandstate.data.invoiceList.map(res1 => {
                                            return (
                                                <option value={res1.xero_invoiceNumber}>
                                                    {res1.xero_invoiceNumber} {res1.xero_invoiceNumber != "All" ? res1.canView ? "(Approved)" : "(Draft)" : "" } 
                                                </option>
                                            )
                                        })}
                                    </select>

                                    {((selectedInvoiceNumber && selectedInvoiceNumber != "All") || (Brandstate?.data?.invoiceList?.length == 1)) && checkInvoiceCanView() &&
                                        <div className='title m-l-10'>
                                            <Icon style={{ fontSize: "2rem" }} className="text-info link-pointer-icon" data-toggle="tool-tip"
                                                onClick={() => { viewInvoice() }} title="View Invoice">visibility</Icon>
                                        </div>
                                    }
                                </>
                            }


                        </>
                    }

                </div>
            </div>

        </div>
        <div className="d-flex row col-12 override-m-p my-3">

            <div className="d-flex row col-11 align-items-center">

                <div className="col-md-3 d-flex dashboard-filter-box">
                    {groupBrandList.length > 0 &&
                        <div className="form-group w-100">
                            <label>Group </label>
                            <div>
                                <Select
                                    value={selectedGroupBrand}
                                    className="w-100 basic-multi-select"
                                    // defaultValue={{ label: '', value: '' }}
                                    cacheOptions
                                    isClearable
                                    options={groupBrandList}
                                    onChange={(value, e) => {
                                        if (e.action == "select-option") {
                                            setSelectedGroupBrand(value)
                                        }
                                        if (e.action == "clear") {
                                            setSelectedGroupBrand(defaultOption)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>

                <div className="col-md-3 d-flex dashboard-filter-box">
                    {brandList.length > 0 &&
                        <div className="form-group w-100">
                            <label>Brand </label>
                            <div>
                                <Select
                                    value={selectedBrand}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={brandList}
                                    // onInputChange={(value, e) => {
                                    //     if (value.length >= 3) {
                                    //         getBrandNameApiCall(value)
                                    //     }
                                    // }}
                                    onChange={(value, e) => {
                                        if (e.action == "select-option") {
                                            setSelectedBrand(value)
                                        }
                                        if (e.action == "clear") {
                                            setSelectedBrand(defaultOption)
                                        }

                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>

                <div className="col-md-3 d-flex dashboard-filter-box w-100 invoice-info">
                    {brandList.length > 0 &&
                        <div className="form-group w-100">
                            <label>Month </label>  
                            {isInvoiceData &&
                                <span className="future-action infoicon" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}>
                                    <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Data from previous month may be included in invoice.">info</Icon>
                                </span>
                            }

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // margin="normal"
                                    // variant="inline"
                                    inputVariant="outlined"
                                    inputFormat="yyyy-MM"
                                    views={['year', 'month']}
                                    value={selectedMonth}
                                    clearable={true}
                                    maxDate={new Date()}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => {
                                                setselectedMonth(null)
                                            }} >
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                    InputAdornmentProps={{
                                        position: "start"
                                    }}
                                    onChange={(value, e) => {
                                        setselectedMonth(value)
                                    }}
                                >

                                </KeyboardDatePicker>
                                {/* <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    variant="inline"
                                    inputVariant="outlined"
                                    inputFormat="yyyy-MM"
                                    views={['year', 'month']}
                                    value={selectedMonth}
                                    onChange={(value, e) => { 
                                        setselectedMonth(value)
                                    }}
                                    onClose={(value, e) => {
                                        console.log(value, e)
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => {
                                                setselectedMonth(null)
                                            }} >
                                                <ClearIcon />
                                            </IconButton>
                                        )
                                    }}
                                    InputAdornmentProps={{
                                        position: "start"
                                    }}
                                /> */}
                            </MuiPickersUtilsProvider>

                        </div>
                    }
                </div>

                {userstate.role != Roles.bank &&
                    <div className="col-md-3 d-flex dashboard-filter-box">
                        <div className="form-group w-100">
                            <label>Publisher</label>
                            <div>
                                <Select
                                    value={selectedPublisher}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={publisherList}
                                    onChange={(value, e) => {
                                        if (e.action == "select-option") {
                                            setSelectedPublisher(value)
                                        }
                                        if (e.action == "clear") {
                                            setSelectedPublisher(defaultOption)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }

                {havingMultipleCustomers && (selectedGroupBrand.value != "All" || selectedBrand.value != "All") && selectedPublisher.value == "ATR" &&
                    <div className="col-md-3 d-flex dashboard-filter-box">
                        <div className="form-group w-100">
                            <label>Customer Type</label>
                            <div>
                                <Select
                                    value={selectedTranCustomerType}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={tranCustomerTypes}
                                    onChange={(value, e) => {
                                        if (e.action == "select-option") {
                                            setSelectedTranCustomerType(value)
                                        }
                                        if (e.action == "clear") {
                                            setSelectedTranCustomerType(defaultOption)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }

                {offerTypeList && offerTypeList.length > 0 &&
                    <div className="col-md-3 d-flex dashboard-filter-box">
                        <div className="form-group w-100">
                            <label>Offer Type</label>
                            <div>
                                <Select
                                    value={selectedOfferType}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={offerTypeList}
                                    onChange={(value, e) => {
                                        if (e.action == "select-option") {
                                            setSelectedOfferType(value);
                                        }
                                        if (e.action == "clear") {
                                            setSelectedOfferType(defaultOption);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="d-flex row col-1 override-m-p p-t-7">
                <div className="col-12 d-flex dashboard-filter-box w-100">
                    <div className="form-group w-100">
                        <button className={`btn btn-sm nav-link tab-selector w-100 active btn-primary m-t-20`}
                            disabled={selectedGroupBrand && selectedGroupBrand.label == "All" && selectedBrand && selectedBrand.label == "All"}
                            onClick={(e) => downloadExcel()}>
                            Export
                        </button>
                    </div>
                </div>
            </div>

            {/* {userstate.role == Roles.bank && selectedBrand.label && selectedBrand.label != "All" && selectedMonth &&
            <div className="col-md-2 d-flex align-items-center w-100">
                <div className="form-group w-100">
                    <button className={`btn btn-sm nav-link tab-selector w-100 active btn-primary m-t-20 view-invoice`}  onClick={(e) => viewInvoice()} >
                        View Invoice
                    </button>
                </div>
            </div>} */}

            {/* {selectedBrand && selectedMonth && selectedBrand.label  && selectedBrand.label != "All" ?
                <div className="col-md-2 d-flex align-items-center w-100">
                    <div className="form-group w-100">
                        <button className={`btn btn-sm nav-link tab-selector w-100 active btn-primary m-t-20`} >
                            Download Invoice <i className="fa fa-download"></i>
                        </button>
                    </div>
                </div>
            : "" } */}
        </div>
        <div className="row justify-content-center ">
            {cardList.map(res => {
                return <div className="col-xl-2 col-lg-2">
                    <div className="card pill">
                        <div className={classNames("card-body text-center pr-0 pl-0",
                            { 'blurText': Brandstate.data[res.field] == 0 }, { 'blurText': (["totalCost","totalSuccessFee"].includes(res.field) && selectedBrand.label == "All" && selectedGroupBrand.label == "All") })}>
                            <div>
                                <div className="icon-with-bg">
                                    {res.iconType === 'material' ? <Icon className="text-primary" fontSize="inherit">{res.icon}</Icon>
                                        : <i className={`text-primary fa ${res.icon}`} ></i>}
                                </div>
                                {res.type == "number" ?
                                    <p className="bold">{Brandstate.data[res.field] ? numberWithCommas(Brandstate.data[res.field].toFixed(2)) :
                                        numberWithCommas(res.defaultValue)}</p>
                                    :
                                    <p className="bold">{Brandstate.data[res.field] ? numberWithCommas(Brandstate.data[res.field]) :
                                        numberWithCommas(res.defaultValue)}</p>
                                }

                                <p>{res.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            })}

            <div className="col-xl-2 col-lg-2">
                <div className="card pill">
                    <div className={classNames("card-body text-center pr-0 pl-0")}>

                        {selectedPublisher.value != "All" &&
                            <div className='upload-date-box'>
                                <div className="icon-with-bg">
                                    <Icon className="text-primary" fontSize="inherit">today</Icon>
                                </div>
                                {/* {ingestionLogDate.uploadedDate &&
                                    <p className="bold title">
                                        Last Updated: {ingestionLogDate.uploadedDate}
                                    </p>} */}
                                {/* {ingestionLogDate.next_ingestion_date &&
                                    <p className="bold sub-title">
                                        Next Update: {ingestionLogDate.next_ingestion_date}
                                    </p>} */}
                                {ingestionLogDate.last_trans_date &&
                                    <p className="bold title m-t-15">
                                        Last Transaction : {ingestionLogDate.last_trans_date}
                                    </p>}
                            </div>
                        }

                        {selectedPublisher.value == "All" &&
                            <div className='upload-date-box'>
                                <div className="icon-with-bg">
                                    <Icon className="text-primary" fontSize="inherit">today</Icon>
                                </div>
                                <div className="bold title m-t-15 c-pointer" onClick={() => setShowIngestionLogList(true)} >
                                    View Last Transaction
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>

        </div>

        <ChartComponent filter={filter} />

        <ViewLastTransactionModal close={() => setShowIngestionLogList(false)}
            ingestionLogList={ingestionLogList}
            show={showIngestionLogList} />

        <SessionExpiryModal
            isOpen={sessionExpiryModal}
        />

    </>
}